import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import VueLazyload from "vue-lazyload";
import infiniteScroll from "vue-infinite-scroll";
import i18n from "./i18n";
import axios from "axios";
import VueScrollTo from "vue-scrollto";
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(VueObserveVisibility);
Vue.use(VueScrollTo);

axios.defaults.auth = {
  username: process.env.VUE_APP_USERNAME,
  password: process.env.VUE_APP_PASSWORD
};
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(VueLazyload);
Vue.use(infiniteScroll);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
