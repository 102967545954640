import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

// const auth = {
//   username: process.env.VUE_APP_USERNAME,
//   password: process.env.VUE_APP_PASSWORD
// };

export default new Vuex.Store({
  state: {
    site: [],
    inview: true
  },
  actions: {
    async loadSite({ commit }, locale) {
      await axios
        .post(
          "/api/query",
          {
            query: "site",
            select: {
              title: true,
              url: true,
              home: {
                query: "site.find('home')",
                select: {
                  text: `page.content('${locale}').home.kirbytext`,
                }
              },
              menu: {
                query: "site.menulist.toStructure",
                select: {
                  title: `structureItem.content('${locale}').title`,
                  id: "structureItem.id",
                  subpages: {
                    query: "structureItem.subpages.toStructure",
                    select: {
                      title: `structureItem.content('${locale}').title`,
                      id: "structureItem.id"
                    }
                  }
                }
              },
              sections: {
                query: "site.children.listed",
                select: {
                  title: `page.content('${locale}').title`,
                  uid: true
                }
              },
              logos: {
                query: "page('home').images.sortBy('sort')",
                select: {
                  url: true
                }
              }
            }
          }
          // { auth }
        )
        .then(function(response) {
          commit("SET_SITE", response.data.result);
        });
    }
  },
  mutations: {
    SET_SITE(state, data) {
      state.site = data;
    }
  }
});
