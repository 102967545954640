import Vue from "vue";
import Router from "vue-router";
import VueI18n from "@/i18n.js";

Vue.use(Router);

export default new Router({
  mode: "history",
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },
  routes: [
    {
      path: "/:lang",
      component: {
        render: h => h("router-view")
      },
      beforeEnter(to, from, next) {
        const lang = to.params.lang;
        if (!["en", "de"].includes(lang)) return next("de");
        if (VueI18n.locale !== lang) {
          VueI18n.locale = lang;
        }
        return next();
      },
      children: [
        {
          path: "",
          name: "default",
          component: () =>
            import(/* webpackChunkName: "Home" */ "./views/Home.vue")
        },
        {
          path: "*",
          name: "home",
          component: () =>
            import(/* webpackChunkName: "Home" */ "./views/Home.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/de/"
    }
  ]
});
